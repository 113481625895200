import {Link, graphql, StaticQuery} from 'gatsby'
import React from 'react'
import Hero from './hero'
import Navigation from './Navigation'
import Icon from './icon'
import Logo from '../images/logo.svg'
import {cn} from '../lib/helpers'

import styles from './header.module.css'

const Header = ({location, onHideNav, onShowNav, showNav, hero}) => {
  // console.log({hero})

  return (
    <>
      <div className={location === '/' ? styles.rootHome : styles.root}>
        <div className={styles.wrapper} style={{alignItems: `center`}}>

          <div className={styles.branding}>
            <Link to='/' aria-label='Logo link to home page'><Logo /></Link>
          </div>

          {/* <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav} aria-label='Mobile Navigation'>
            <Icon symbol='hamburger' iconClass={showNav ? 'onShowNav' : 'onHideNav'} />
          </button> */}

          <button className={showNav ? styles.menuToggleOn : styles.menuToggle} onClick={showNav ? onHideNav : onShowNav} aria-label='Mobile Menu' />

          <StaticQuery
            query={graphql`
            query sanityNav {
              sanityNavigation(_id: { eq: "6471870a-fd4f-4525-834f-388fff34f88b" }) {
                links {
                  _key
                  title
                  siteLink
                  links {
                    _key
                    title
                    siteLink
                  }
                }
              }
            }
          `}
            render={data => (
              <nav className={cn(styles.nav, showNav && styles.showNav)}>
                <Navigation nav={data.sanityNavigation} main />
              </nav>
            )}
          />

        </div>
      </div>

      {hero && hero[0].__typename && <Hero hero={hero[0]} home={location === '/' ? 'true' : 'false'} />}

    </>
  )
}

export default Header
