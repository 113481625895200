import React from 'react'
import {Link} from 'gatsby'

import styles from './header.module.css'

const SubLinks = (props) => {
  return (
    <ul className='sublinks'>
      {props.links.map((sublinks, i) => (
        <li key={i}>

          {sublinks.siteLink.includes('https') || sublinks.siteLink.includes('http') || sublinks.siteLink.includes('mailto') || sublinks.siteLink.includes('tel') ? (
            <a href={sublinks.siteLink} target='_blank' rel='noreferrer' title={sublinks.title}>
              {sublinks.title}
            </a>
          ) : (
            <Link to={sublinks.siteLink} title={sublinks.title}>{sublinks.title}</Link>
          )}

        </li>
      ))}
    </ul>
  )
}

function Navigation ({nav, main}) {
  // console.log({nav})
  return (
    <ul className={styles.topMainNav}>

      {nav.links.map((links, i) => (
        <li key={i}>

          {links.siteLink ? (
            <>
              {links.siteLink.includes('https') || links.siteLink.includes('http') ? (
                <a href={links.siteLink} target='_blank' rel='noopener noreferrer' title={links.title}>
                  {links.title}
                </a>
              ) : (
                <Link to={links.siteLink} title={links.title}>{links.title}</Link>
              )}

            </>
          ) : (
            <>
              {links.title}
            </>
          )}

          {links.links && links.links.length
            ? <SubLinks links={links.links} />
            : ''}
        </li>
      ))}
    </ul>
  )
}

export default Navigation
