import React from 'react'

import styles from './socialmedia.module.css'

const SocialMedia = ({data}) => {
  const setTarget = (link) => {
    if (link.substr(0, 3) === 'tel') {
      return '_self'
    } else {
      return '_blank'
    }
  }
  // console.log({data})
  return (
    <div className={styles.socialMedia}>
      <ul>
        {data.map(logo => (
          <li key={logo._key}>
            <a href={logo.link} title={`${logo.title} link`} target={setTarget(logo.link)} rel='noopener noreferrer'><img src={logo.logoImage.asset.url} title={`${logo.title} icon`} /></a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default SocialMedia
