import React from 'react'

import {buildGalleryObj} from '../../lib/helpers'
import {imageUrlFor} from '../../lib/image-url'

import styles from './gallery.module.css'

function Gallery (props) {
  // console.log({props})
  return (
    <div className={styles.galleryWrapper}>

      {props.images.map(slide => (
        <img
          key={slide._key}
          src={imageUrlFor(buildGalleryObj(slide))
            .width(220)
            // .height(Math.floor((9 / 16) * 1200))
            // .fit('crop')
            .url()}
        />
      ))}

    </div>
  )
}
export default Gallery
