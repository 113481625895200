import {graphql, StaticQuery} from 'gatsby'
import React, {useState} from 'react'
import Layout from '../components/layout'
import AlertBar from '../components/AlertBar/index'

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
      seoSettings {
        title
        description
        openGraphImage {
          asset {
            path
            url
          }
        }
      }
    }
    alert : sanitySiteSettings {
      _rawAlert
    }
  }
`

function LayoutContainer (props) {
  const [showNav, setShowNav] = useState(false)
  function handleShowNav () {
    setShowNav(true)
  }
  function handleHideNav () {
    setShowNav(false)
  }
  return (
    <StaticQuery
      query={query}
      render={data => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the Studio at http://localhost:3333 and some content in "Site settings"'
          )
        }
        return (
          <>
            {data.alert._rawAlert && data.alert._rawAlert.alertEnabled === true && data.alert._rawAlert.alertCookieName && (
              <AlertBar message={data.alert._rawAlert.alertBody} alertCookieName={data.alert._rawAlert.alertCookieName.current} alertCookieMaxAge={data.alert._rawAlert.alertCookieMaxAge} />
            )}
            <Layout
              {...props}
              showNav={showNav}
              siteTitle={data.site.title}
              onHideNav={handleHideNav}
              onShowNav={handleShowNav}
              socialMedia={data.socialMedia}
            />
          </>
        )
      }}
    />
  )
}

export default LayoutContainer
