import React from 'react'
import Img from 'gatsby-image'
import {getFluidGatsbyImage} from 'gatsby-source-sanity'
import clientConfig from '../../../client-config'

// https://www.npmjs.com/package/react-responsive-carousel
import {Carousel} from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import './slideshow.css'

function Slideshow (props) {
  console.log({props})
  const fluidProps = (slide) => getFluidGatsbyImage(slide.asset._ref, {maxWidth: 640}, clientConfig.sanity)
  return (
    <div style={{maxWidth: '900px', margin: '0 auto'}}>
      <Carousel showStatus={false} dynamicHeight swipeable showThumbs={false} >
        {props.slides.map(slide => (
          <Img key={slide._key} fluid={fluidProps(slide.slideImage)} alt={slide.altText} />
        ))}
      </Carousel>
    </div>
  )
}
export default Slideshow
