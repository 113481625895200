import React from 'react'
import Img from 'gatsby-image'
import {useStaticQuery, graphql} from 'gatsby'
import {Location} from '@reach/router'
import Header from './header'
// import {SkipNavLink, SkipNavContent} from '@reach/skip-nav'
// import '@reach/skip-nav/styles.css' // this will auto show and hide the link on focus

import SocialMedia from './SocialMedia'

import styles from './layout.module.css'

// import '@reach/skip-nav/styles.css'

const Layout = ({children, onHideNav, onShowNav, showNav, siteTitle, title, hero}) => {
  const data = useStaticQuery(graphql`
  {
    sanitySiteSettings {
      title
      mainImage {
        asset {
          # fluid(maxWidth: 440, maxHeight: 256) {
          #   ...GatsbySanityImageFluid
          # }
          fixed(height: 256, width: 400) {
            ...GatsbySanityImageFixed
          }
        }
      }
      socialMedia {
        _key
        _type
        title
        link
        logoImage {
          asset {
            url
          }
        }
      }
      logos {
        _key
        _type
        title
        link
        logoImage {
          asset {
            # fluid(maxWidth: 80) {
            #   ...GatsbySanityImageFluid
            # }
            fixed( width: 80) {
              ...GatsbySanityImageFixed
            }
          }
        }
      }
      seoSettings {
        title
        description
        openGraphImage {
          asset {
            url
          }
        }
      }
    }
  }
`)
  // console.log({data})
  return (
    <>
      <a className={styles.skipLink} href='#main'>Skip to main</a>
      {/* <SkipNavLink style={{zIndex: '2', margin: '0 auto', left: '50%'}} /> */}
      {/* <div className={location.pathname === '/' ? styles.contentHome : styles.content}> */}
      <Location children={children}>

        {({location}) => {
          // console.log({location})
          // console.log({mainImage})
          // console.log({siteTitle})
          return (
            <div
              style={{background: '#ebe1ce'}}
              className={
                location.pathname.replace(/\//g, '') === ''
                  ? `${styles.contentHome}`
                  : location.pathname.replace(/\//g, '')
              }
            >
              <Header
                location={location.pathname}
                siteTitle={siteTitle}
                onHideNav={onHideNav}
                onShowNav={onShowNav}
                showNav={showNav}
                hero={hero}
              />
              <a id='main' />
              <SocialMedia data={data.sanitySiteSettings.socialMedia} />
              <div className={styles.root}>{[children]}</div>

              <footer className={styles.footer}>
                <div className={styles.topFooter}>
                  <div className={styles.topFooterWrapper}>
                    {/* Footer Image */}
                    <div className={styles.footerMainImage}>
                      <Img fixed={data.sanitySiteSettings.mainImage.asset.fixed} alt={title} />
                    </div>
                    {/* Footer Logos */}
                    <div className={styles.footerLogoWrapper}>
                      {data.sanitySiteSettings.logos.map(logo => (
                        <a href={logo.link} key={logo._key} target='_blank' rel='noopener noreferrer' title={logo.title}>
                          <Img fixed={logo.logoImage.asset.fixed} alt={logo.title} />
                        </a>
                      ))}
                    </div>

                  </div>
                </div>
                <div className={styles.bottomFooter}>
                  <div className={styles.footerWrapper}>
                    <span> &copy; {new Date().getFullYear()} {data.sanitySiteSettings.title} All rights reserved.
                      { ' ' } Call Lindgren: <a href='tel:970-226-5677' title='Call Us link' target='_self' rel='noopener noreferrer'>970-226-5677</a>
                    </span>
                    <span> Site by <a href='https://www.variantstudios.com' rel='noopener noreferrer' target='_blank'>Variant Studios</a> </span>
                  </div>
                </div>
              </footer>

            </div>
          )
        }}
      </Location>

      {/* </div> */}
    </>
  )
}

export default Layout
