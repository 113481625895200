import React, {useState} from 'react'
import Image from 'gatsby-image'
import ReactPlayer from 'react-player'
import {Carousel} from 'react-responsive-carousel'

import styles from './hero.module.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './page/slider.css'

import PauseButtonIcon from '../images/icons/pause.svg'
import PlayButtonIcon from '../images/icons/play.svg'

import AudioButtonIcon from '../images/icons/audio.svg'
import MuteButtonIcon from '../images/icons/mute.svg'

const Hero = ({home, hero, hero: {__typename, videoType, videoID, slides, asset, altText}}) => {
  // console.log({videoType})
  // console.log({home})
  // State
  const [videoData, setVideoData] = useState({
    muted: true,
    volume: 0,
    playing: false,
    pause: true,
    ready: false
  })

  // Play or Pause video function
  // const handlePlayPause = () => {
  //   setVideoData({playing: !videoData.playing})
  // }

  const handlePause = () => {
    setVideoData({...videoData, playing: false})
  }

  const handlePlay = () => {
    setVideoData({...videoData, playing: true})
  }
  const handleAudio = () => {
    setVideoData({...videoData, muted: false, volume: 0.5})
  }
  const handleMute = () => {
    setVideoData({...videoData, muted: true, volume: 0})
  }

  return (
  // console.log({videoData}),
    <>

      {__typename === 'SanityVideo' && (

        <header className={styles.mainImage}>
          {videoData.ready === true && (
            <div className={home === 'true' ? 'videoHomeBtnWrapper' : 'videoBtnWrapper'}>
              {home === 'false' && (
                <>

                  {videoData.muted === true ? (
                    <div className={styles.audioWrapper}>
                      <div className={styles.audioWrapperText}>unmute &rarr;{/* <span>play</span> <span>audio</span>  */}</div>
                      <button className={styles.audioBtn} onClick={handleAudio} aria-label='Video Audio'><AudioButtonIcon /></button>
                    </div>
                  ) : (
                    <button className={styles.audioBtn} onClick={handleMute} aria-label='Video Audio'><MuteButtonIcon /></button>
                  )}
                </>
              )}
              {videoData.playing ? (
                <button className={styles.pauseBtn} onClick={handlePause} aria-label='Pause Video'><PauseButtonIcon /></button>
              ) : (
                <button className={styles.playBtn} onClick={handlePlay} aria-label='Play Video'><PlayButtonIcon /></button>
              ) }

            </div>
          )}

          {videoType === 'auto' ? (
            <ReactPlayer
              url={`https://vimeo.com/${videoID}?background=1`}
              className={styles.embedContainer}
              muted={videoData.muted}
              volume={videoData.volume}
              playsinline
              light={false}
              loop
              width='100%'
              height='100%'
              style={{height: '56.25vw'}}
              playing={videoData.playing}
              playIcon='true'
              controls={false}
              onReady={() => setVideoData({ready: true, playing: true, muted: true, volume: 0})}
              onStart={() => setVideoData({playing: true, ready: true, muted: true, volume: 0})}
            />
          ) : (
            <ReactPlayer
              url={`https://vimeo.com/${videoID}?background=1`}
              className={styles.embedContainer}
              muted={videoData.muted}
              volume={videoData.volume}
              light={false}
              loop
              width='100%'
              height='100%'
              style={{height: '56.25vw'}}
              playing={videoData.playing}
              playIcon='true'
              controls={false}
              onReady={() => setVideoData({ready: true, muted: false, volume: 0.5})}
              onStart={() => setVideoData({playing: true, ready: true, muted: false, volume: 0.5})}
            />
          )}

        </header>
      )}

      {__typename === 'SanitySlideshow' && (
        <header className={styles.mainImage}>
          <Carousel interval={10000} showThumbs={false} showStatus={false} showIndicators={false} autoPlay stopOnHover={false} useKeyboardArrows dynamicHeight infiniteLoop emulateTouch>
            {slides.map(slide => (
              <div key={slide._key}>
                <Image fluid={slide.slideImage.asset.fluid} alt={slide.altText} />
              </div>
            ))}
          </Carousel>
        </header>
      )}

      {__typename === 'SanityMainImage' && (

        <div className='singleImage'>
          <Image fluid={asset.fluid} alt={altText} />
        </div>

      )}

    </>
  )
}
export default Hero
